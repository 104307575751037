.reviewDiv-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  margin: 0 auto;
}

h3.orangeHeading {
  color: orangered;
  font-size: 15px !important;
  font-weight: bold;
}

.reviewDiv-nav h4 {
  color: var(--primary-colour);
  /* text-decoration: none !important; */
  text-decoration: none;
}

.reviewDiv-nav > a:hover > h4 {
  color: #fff;
  transition-duration: 0.2s;
}

.reviewDiv-nav a {
  border-top: 0.15rem solid var(--primary-colour);
  text-decoration: none !important;
  background-color: var(--grey-colour);
  padding: 0.5rem 1rem;
  margin: 0;
  width: 100%;
  font-weight: 10;
  border-radius: 1.25rem 1.25rem 0 0;
  border: none;
}

.reviewDiv-nav > a:hover,
.reviewDiv-nav.bottom > a:hover {
  background-color: var(--primary-colour);
  transition-duration: 0.4s;
}

.reviewDiv-nav a:last-of-type {
  border-bottom: 0.2rem solid var(--primary-colour);
}

.reviewDiv-nav .review-active {
  position: relative;
  background-color: #fff;
  margin-bottom: -0.15rem;
  /* border: 0.2rem solid var(--primary-colour); */
  border: 0.2rem solid var(--primary-colour);
  border-bottom: none;
}

.reviewDiv-nav.bottom .review-active {
  position: relative;
  background-color: #fff;
  margin-top: -0.17rem;
  /* border: 0.2rem solid var(--primary-colour);
    border-top: none; */
  border: 0.2rem solid var(--primary-colour);
  border-top: none;
}

.reviewDiv-nav.bottom a {
  border-radius: 0 0 1.25rem 1.25rem;
  border: none;
}

.reviewDiv-nav a:first-of-type {
  margin-right: 0.5rem;
}

.reviewDiv-nav a:last-of-type {
  border-bottom: none;
  margin-left: 0.5rem;
}

@media (min-width: 600px) {
  .reviewDiv-nav a {
    padding: 0.5rem 2rem;
  }
}

@media (min-width: 640px) {
  .reviewDiv-nav a {
    padding: 0.8rem 2rem;
  }
}

@media (min-width: 732px) {
  .reviewDiv-nav a {
    padding: 1.2rem 2rem;
  }

  h3.orangeHeading {
    font-size: larger !important;
  }
}

@media (min-width: 800px) {
  .reviewDiv-nav a {
    padding: 1.5rem 2rem;
  }
}
