.language-btn-container{
    display: grid;
    place-items: center;
    padding-bottom: 3rem;
}

Button.language-btn-english{
    color: black;
    font-weight: 700;
    padding: 0.9rem 1.0rem; 
    border: black;
    border-style: solid;
    border-radius: 1rem;
    cursor: pointer;
    transition: all .3s ease-out;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.225);
    font-size: 15px;
    margin-bottom: 10px;
    width: 100%;
}
  
Button.language-btn-english:hover{
    text-decoration: none;
    background-color: rgba(30, 195, 30, 0.5);
}

Button.language-btn-english:active {
    background-color: rgba(30, 195, 30, 0.5);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.225);
    transform: translateY(4px);
}
  
Button.language-btn-french{
    color: black;
    font-weight: 700;
    padding: 0.9rem 1.0rem;
    border: black;
    border-style: solid;
    border-radius: 1rem;
    cursor: pointer;
    transition: all .3s ease-out;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.225);
    font-size: 15px;
    width: 100%;
}
  
Button.language-btn-french:hover{
    text-decoration: none;
    background-color: rgba(30, 139, 195, 0.5);
}

Button.language-btn-french:active {
    background-color: rgba(30, 139, 195, 0.5);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.225);
    transform: translateY(4px);
}

.language-btn-title{
    justify-content: center;
    text-align: center;
}

.language-btn-content{
    justify-content: center;
    text-align: center;
}

@media (min-width: 768px) { 
    .language-btn-title{
        justify-content: left;
        text-align: left;
    }

    .language-btn-content{
        justify-content: left;
        text-align: left;
    }
}
  