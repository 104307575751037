.study_happyFace_div {
    position: relative;
    right: 1.5rem;
}

.study_happyFace_div>h3 {
    text-align: center;
    position: relative;
    left: 2rem;
}

.study_happyFace_container {
    /* display: flex; */
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;
}

.study_happyFace_image_container {
    width: 350px;
    margin: 0 auto 1rem auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    text-align: left;
}

.happyface {
    max-width: 32px;
    max-height: 32px;
}

.happyFace-score-container {
    position: relative;
    left: 2rem;
    text-align: center;
    margin: 1rem 0 1.5rem;
}

@media (min-width: 370px) {
    .study_happyFace_div {
        right: 1rem;
    }
    
}

@media (min-width: 384px) {

    .study_happyFace_div {
        right: 0.8rem;
    }

    .study_happyFace_div>h3 {
        left: 1.5rem;
    }
    
}

@media (min-width: 390px) {

    .study_happyFace_div {
        right: 0.6rem;
    }
    
}

@media (min-width: 419px) {

    .study_happyFace_div {
        left: 0.5rem;
    }
    .study_happyFace_div>h3 {
        left: 0.3rem;
    }

    .happyFace-score-container {
        position: relative;
        left: 0.5rem;
    }
    
}


@media (min-width: 600px) {

    .study_happyFace_image_container {
        position: relative;
        left: 1rem;
    }
    
}


@media (min-width: 732px) {

    .study_happyFace_div>h3 {
        left: 0.5rem;
    }

    .happyFace-score-container {
        left: 1rem;
    }
    
}

@media (min-width: 760px) {

    .study_happyFace_container {
        display: flex;
    }
    
}

@media (min-width: 906px) {

    .study_happyFace_image_container {
        width: 20rem;
    }

    .study_happyFace_container {
        padding: 0 4rem;
    }
    
}