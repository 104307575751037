.wrapper {
  margin-top: 0;
}

.notice-container > h5 {
  text-align: center;
  margin-bottom: 1rem;
  padding: 0rem 1rem;
}

.home-page-header .header {
  height: 0px !important;
}
