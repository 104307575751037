.circle-group-container-Qstep5 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: space-around;
}

.circle-group-labels {
  display: grid;
  place-items: center;
}

.circle-group-container-Qstep5-row {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin: 0 auto;
}

.circle-group-labels-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RadioGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 667px) {
  .circle-group-container-Qstep5 {
    display: none;
  }

  .circle-group-labels {
    display: none;
  }

  .circle-group-container-Qstep5-row {
    display: flex;
  }

  .circle-group-labels-row {
    display: grid;
  }
}
