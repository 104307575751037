.user-container {
  display: flex;
  flex-direction: column;
  background-color: rgb(245, 245, 245);
  overflow-x: hidden;
  margin-bottom: 2rem;
}

.user-nav {
  margin-top: 6.8rem;
}

.user-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
  margin-left: 1rem;
}

.captions {
  width: 90%;
}

.intervalDropdown {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
  margin-left: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 80%;
}

.dropdown{
  max-width: 256px;
  position: relative;
  left: 140px;
  bottom: 8px;
}

.t1{
  text-align: center;
}

.t2{
  text-align: center;
}

.t3{
  position: relative;
}

.t4{
  position: relative;
}

.painAvg-custom-tooltip{
  border: 1px solid #000000;
  background-color: rgba(255, 255, 255);
  position: relative;
  left: 3rem;
  top: 50rem;
  width: 50%;
}

.painAvg-custom-tooltip > p{
  font-size: 12px;
}

.plots-custom-tooltip{
  border: 1px solid #000000;
  background-color: rgba(255, 255, 255);
  position: relative;
  left: 3rem;
  top: 80rem;
  width: 50%;
}

.plots-custom-tooltip > p{
  font-size: 12px;
}

.plots2-custom-tooltip{
  border: 1px solid #000000;
  background-color: rgba(255, 255, 255);
  position: relative;
  left: 3rem;
  top: 120rem;
  width: 50%;
}

.plots2-custom-tooltip > p{
  font-size: 12px;
}

.treatmentAvg-custom-tooltip{
  border: 1px solid #000000;
  background-color: rgba(255, 255, 255);
  position: relative;
  left: 3rem;
  top: 50rem;
  width: 70%;
}

.treatmentAvg-custom-tooltip > p{
  font-size: 12px;
}

.treatmentAvg2-custom-tooltip{
  border: 1px solid #000000;
  background-color: rgba(255, 255, 255);
  position: relative;
  left: 1rem;
  top: 90rem;
  width: 70%;
}

.treatmentAvg2-custom-tooltip > p{
  font-size: 12px;
}

.treatmentAvg3-custom-tooltip {
  border: 1px solid #000000;
  background-color: rgba(255, 255, 255);
  position: relative;
  left: 1rem;
  top: 120rem; /* Adjusted for the third chart */
  width: 70%;
}

.treatmentAvg3-custom-tooltip > p {
  font-size: 12px;
}

.chartWlegend {
  flex-direction: row;
}

.chartWrapper {
  margin-bottom: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -3rem;
}

.legend{
  display: block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 90%;
  /* min-width: 300px; */
  /* margin-left: 4rem; */
}

.legend2{
  position: relative;
  left: 1000px;
  bottom: 750px;
  overflow-x: hidden;
  /* border: 1px solid #000000; */
}

.emojis{
  display: flex;
  flex-direction: column;
}

.emoji {
  padding: 5px;
}

.red {
  background-color: #e3242b;
}

.yellow {
  background-color: #effd5f;
}

.green {
  background-color: #03c04a;
}

.levels{
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 5px;
}

.chooseTimes1_en::before {
  content: "From";
  margin-right: 5px;
  font-size: 20px;
}

.chooseTimes1_fr::before {
  content: "De";
  margin-right: 5%;
  font-size: 20px;
}
.chooseTimes_en::before{
  content: "Or select a date range";
  margin-right : 5px;
  font-size : 20px;
}
.chooseTimes_fr::before{
  content: " Ou sélectionnez une plage de dates";
  margin-right : 5px;
  font-size : 20px;
}

.select_date{
  font-size: 10px;
}

.chooseTimes2_en::before{
  content: "To";
  margin-right: 5px;
  font-size: 20px;
}

.chooseTimes2_fr::before{
  content: "A";
  margin-right: 5px;
  font-size: 20px;
}

.recharts-wrapper {
  position: unset !important;
}

.spacing{
  word-spacing: 200px;
}

.button2{
  position: relative;
  left: 300px;
  bottom: 45px;
}

.chart{
  bottom: 100px;
}

.chart2{
  bottom: 400px;
}

.bar{
  margin-left: -2rem;
}

Button.load-btn{
  background-color: rgba(241, 90, 43, 0.9);
  border: none;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.225);
}

Button.load-btn:hover{
  text-decoration: none;
  background-color: rgba(241, 90, 43, 0.9);
}

/* Wrapper for the symptoms dropdown */
.multiSelectSymptoms {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-bottom: 10px;
  align-items: flex-start; /* Aligns label and dropdown */
}

/* Label styling */
.multiSelectSymptoms label {
  font-size: 16px; /* Adjust label size */
  font-weight: bold;
  margin-bottom: 5px;
  color: #333; /* Match the theme */
}

/* Dropdown styling */
.select_symptoms {
  width: 200px; /* Adjust width */
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white; /* Match theme */
  cursor: pointer;
}

/* Add hover effect */
.select_symptoms:hover {
  border-color: #296d98; /* Highlight border on hover */
}

/* Add focus effect */
.select_symptoms:focus {
  outline: none;
  border-color: #296d98;
  box-shadow: 0 0 5px rgba(41, 109, 152, 0.5);
}

@media (min-width: 412px) {

  .select_date{
    font-size: 12px;
  }

  .plots-custom-tooltip{
    top: 70rem;
  }

  .plots2-custom-tooltip{
    top: 110rem;
  }

}

@media (min-width: 600px) {

  .select_date{
    font-size: 15px;
  }

  .treatmentAvg-custom-tooltip{
    top: 40rem;
  }
  
  .treatmentAvg2-custom-tooltip{
    top: 75rem;
  }

  .treatmentAvg3-custom-tooltip {
    top: 115rem; /* Slight adjustment for larger screens */
  }
}

@media (min-width: 760px) {
  .painAvg-custom-tooltip > p,
  .plots-custom-tooltip > p,
  .plots2-custom-tooltip > p,
  .treatmentAvg-custom-tooltip > p,
  .treatmentAvg2-custom-tooltip > p,
  .treatmentAvg3-custom-tooltip > p {
    font-size: 15px;
  }

  .plots-custom-tooltip {
    top: 55rem;
  }


  .plots2-custom-tooltip{
    top: 100rem;
  }
}

@media (min-width: 1024px) {
  .painAvg-custom-tooltip {
    top: 45rem;
  }

  .plots-custom-tooltip{
    top: 50rem;
  }

  .plots2-custom-tooltip{
    top: 95rem;
  }

  .treatmentAvg-custom-tooltip{
    top: 50rem;
  }
  
  .treatmentAvg2-custom-tooltip{
    top: 100rem; /* Adjust for screens 1024px wide or more */
  }

  .treatmentAvg3-custom-tooltip {
    top: 130rem; /* Adjust for screens 1024px wide or more */
  }
  /* New styles for scrollable container */
.scrollable-chart-wrapper {
  overflow-x: auto;
  width: 100%; /* Full width of the container */
  padding-bottom: 1rem; /* Optional for spacing */
}

/* Adjust the chart's minimum width to enable scrolling if needed */
.scrollable-chart-wrapper .chart {
  min-width: 800px; /* Set this to the minimum width to allow scrolling */
}

/* You can customize this for different screen sizes if needed */
@media (min-width: 1024px) {
  .scrollable-chart-wrapper .chart {
    min-width: 1000px; /* Adjust this as per larger screen requirements */
  }
}

}
