.orangenext-btn-container {
  color: var(--secondary-colour) !important;
  text-align: center;
  margin: 0rem 0 0;
  padding: 1rem 1.5rem;
}

.orangenext-btn-content {
  max-width: 1100px;
  margin: 0 auto;
  color: var(--primary-colour) !important;

  display: flex;
  flex-direction: row-reverse;
  width: 90%;
}

.home-usage {
  width: 90%;
  text-align: center;
  margin: 20px auto;
}

@media (min-width: 768px) {
  .orangenext-btn-container {
    text-align: right;
  }
}
