.info-alert {
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid #bee5eb;
    color: #0c5460;
    background-color: #d1ecf1;
    border-radius: 5px;
    margin-top: 10px;
}

Button.submit-btn{
    background-color: var(--secondary-colour); 
    color: #FFF;
}

Button.submitted{
    background-color: #00b700 !important;
    color: #FFF !important;
}
  