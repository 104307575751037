.circle-group-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:stretch;
    margin: 2rem 0;
    align-content:space-around;
}
  
.circle-radio-group {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    margin: 0 auto;
}

.circle-radio-group p{
    font-size: 16px;
}

.circle-radio-button-container{
    display: table;
    width: 100%;
    table-layout: fixed;
}

.circle-radio-button{
    display: table-cell;
    text-align: center;
}

@media (min-width: 667px) {

    .circle-radio-group p{
        font-size: 20px;
    }

    .circle-radio-group {
        width: 90%;
    }
    
}
  