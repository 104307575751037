.top-bar{
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #F15A2B;
  padding: 0.75rem 2.3rem 0;
  color: white;
  font-weight: 600;
  box-shadow: 0px 1px 4px #666;
}

.top-bar-left{
  margin-right: auto;
}

.top-bar-right{
  margin-left: auto;
}
/* .top-bar-right p{
 
} */

.top-bar-left ul {
  display: flex;
  flex-direction: row;
}

.top-bar-left ul li{
  padding-right: 1rem;
  margin-right: 1rem;
  border-right: 1px solid;
}

.top-bar-left a:hover,
.top-bar-right p:hover {
  cursor: pointer;
  color: var(--primary-colour);
}