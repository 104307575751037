/* text */

.body-name-sm {
    font-size: 40px;
}

.body-name-sm-fr {
    font-size: 40px;
}

.body-name-lg {
    visibility: hidden;
}

.body-name-lg-fr {
    visibility: hidden;
}

/* jaw */

.jaw-line-sm {
    width: 120px
}

.jaw-line-sm-fr {
    width: 190px
}

.jaw-line-lg {
    visibility: hidden;
}

.jaw-line-lg-fr {
    visibility: hidden;
}

/* left shoulder */

.ls-line-sm {
    width: 210px
}

.ls-line-sm-fr {
    width: 170px
}

.ls-line-lg {
    visibility: hidden;
}

.ls-line-lg-fr {
    visibility: hidden;
}

/* right shoulder */

.rs-line-sm {
    width: 210px
}

.rs-line-sm-fr {
    width: 270px
}

.rs-line-lg {
    visibility: hidden;
}

.rs-line-lg-fr {
    visibility: hidden;
}

/* hips */

.hips-line-sm {
    width: 260px
}

.hips-line-sm-fr {
    width: 320px
}

.hips-line-lg {
    visibility: hidden;
}

.hips-line-lg-fr {
    visibility: hidden;
}

/* fingers */

.fingers-line-sm {
    width: 190px
}

.fingers-line-sm-fr {
    width: 250px
}

.fingers-line-lg {
    visibility: hidden;
}

.fingers-line-lg-fr {
    visibility: hidden;
}

/* right knee */

.rk-line-sm {
    width: 280px
}

.rk-line-sm-fr {
    width: 280px
}

.rk-line-lg {
    visibility: hidden;
}

.rk-line-lg-fr {
    visibility: hidden;
}

/* left knee */

.lk-line-sm {
    width: 210px
}

.lk-line-sm-fr {
    width: 300px
}

.lk-line-lg {
    visibility: hidden;
}

.lk-line-lg-fr {
    visibility: hidden;
}

/* right ankle */

.ra-line-sm {
    width: 290px
}

.ra-line-sm-fr {
    width: 330px
}

.ra-line-lg {
    visibility: hidden;
}

.ra-line-lg-fr {
    visibility: hidden;
}

/* left ankle */

.la-line-sm {
    width: 220px
}

.la-line-sm-fr {
    width: 350px
}

.la-line-lg {
    visibility: hidden;
}

.la-line-lg-fr {
    visibility: hidden;
}

/* upper back */

.ub-line-sm {
    width: 320px
}

.ub-line-sm-fr {
    width: 350px
}

.ub-line-lg {
    visibility: hidden;
}

.ub-line-lg-fr {
    visibility: hidden;
}

/* neck */

.neck-line-sm {
    width: 160px
}

.neck-line-sm-fr {
    width: 150px
}

.neck-line-lg {
    visibility: hidden;
}

.neck-line-lg-fr {
    visibility: hidden;
}

@media (min-width: 640px) {

    /* text */

    .body-name-sm {
        visibility: hidden;
    }
    
    .body-name-sm-fr {
        visibility: hidden;
    }

    .body-name-lg {
        visibility: visible;
        font-size: 40px;  
    }
    
    .body-name-lg-fr {
        visibility: visible;
        font-size: 40px;
    }

    /* jaw */

    .jaw-line-sm {
        visibility: hidden;
    }
    
    .jaw-line-sm-fr {
        visibility: hidden;
    }
    
    .jaw-line-lg {
        visibility: visible;
        width: 105px
    }
    
    .jaw-line-lg-fr {
        visibility: visible;
        width: 190px
    }

    /* left shoulder */
    .ls-line-sm {
        visibility: hidden;
    }

    .ls-line-sm-fr {
        visibility: hidden;
    }

    .ls-line-lg {
        visibility: visible;
        width: 200px
    }

    .ls-line-lg-fr {
        visibility: visible;
        width: 170px
    }

    /* right shoulder */

    .rs-line-sm {
        visibility: hidden;
    }

    .rs-line-sm-fr {
        visibility: hidden;
    }

    .rs-line-lg {
        visibility: visible;
        width: 200px
    }

    .rs-line-lg-fr {
        visibility: visible;
        width: 270px
    }

    /* hips */

    .hips-line-sm {
        visibility: hidden;
    }

    .hips-line-sm-fr {
        visibility: hidden;
    }

    .hips-line-lg {
        visibility: visible;
        width: 260px
    }

    .hips-line-lg-fr {
        visibility: visible;
        width: 320px
    }

    /* fingers */

    .fingers-line-sm {
        visibility: hidden;
    }

    .fingers-line-sm-fr {
        visibility: hidden;
    }

    .fingers-line-lg {
        visibility: visible;
        width: 190px
    }

    .fingers-line-lg-fr {
        visibility: visible;
        width: 250px
    }

    /* right knee */

    .rk-line-sm {
        visibility: hidden;
    }

    .rk-line-sm-fr {
        visibility: hidden;
    }

    .rk-line-lg {
        visibility: visible;
        width: 280px
    }

    .rk-line-lg-fr {
        visibility: visible;
        width: 280px
    }

    /* left knee */

    .lk-line-sm {
        visibility: hidden;
    }

    .lk-line-sm-fr {
        visibility: hidden;
    }

    .lk-line-lg {
        visibility: visible;
        width: 210px
    }

    .lk-line-lg-fr {
        visibility: visible;
        width: 300px
    }

    /* left knee */

    .ra-line-sm {
        visibility: hidden;
    }

    .ra-line-sm-fr {
        visibility: hidden;
    }

    .ra-line-lg {
        visibility: visible;
        width: 290px
    }

    .ra-line-lg-fr {
        visibility: visible;
        width: 330px
    }

    /* left ankle */

    .la-line-sm {
        visibility: hidden;
    }

    .la-line-sm-fr {
        visibility: hidden;
    }

    .la-line-lg {
        visibility: visible;
        width: 220px
    }

    .la-line-lg-fr {
        visibility: visible;
        width: 340px
    }

    /* upper back */

    .ub-line-sm {
        visibility: hidden;
    }

    .ub-line-sm-fr {
        visibility: hidden;
    }

    .ub-line-lg {
        visibility: visible;
        width: 320px
    }

    .ub-line-lg-fr {
        visibility: visible;
        width: 350px
    }

    /* neck */
    .neck-line-sm {
        visibility: hidden;
    }

    .neck-line-sm-fr {
        visibility: hidden;
    }

    .neck-line-lg {
        visibility: visible;
        width: 160px
    }

    .neck-line-lg-fr {
        visibility: visible;
        width: 150px
    }
}