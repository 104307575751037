* {
  margin: 0;
  padding: 0;
  border: 0;
}

.successMsg {
  color: rgb(9, 186, 9);
  font-size: larger;
}

.reset-page {
  width: 100%;
  overflow-x: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.title-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3rem 1rem 3rem 1rem;
  background-color: var(--primary-colour);
}

.title-section h1 {
  position: relative;
  top: 2rem;
  color: white;
  right: 3rem;
  text-align: center;
}

.login-translation {
  width: 100%;
  position: relative;
  right: 1rem;
  bottom: 2rem;
}

.form-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 3rem 2rem;
  width: 100%;
  margin: auto;
  background-color: white;
  box-shadow: 0 12px 24px 0 hsl(220, 15%, 88%), 0 3px 9px 0 hsl(220, 15%, 94%);
}

form {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: auto;
}
form div {
  display: flex;
  margin: 0.55rem 0;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

input {
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 77%);
  padding: 0.5rem 0.75rem;
}
/* .next-btn-container{
  margin: 0;
} */
.submit-btn {
  color: #fff;
  font-weight: 700;
  margin: 2rem 0 0 auto;
  padding: 0.7rem 3rem;
  background-color: var(--secondary-colour);
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-out;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.225);
}

.submit-btn:disabled,
.submit-btn[disabled] {
  color: silver;
  font-weight: 700;
  margin: 2rem 0 0 auto;
  padding: 0.7rem 3rem;
  background-color: grey;
  border: none;
  border-radius: 1rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.225);
}

.submit-btn:hover {
  text-decoration: none;
  background-color: rgba(241, 90, 43, 0.9) !important;
}

.submit-btn:hover[disabled] {
  text-decoration: none;
  background-color: grey !important;
  cursor: default;
}

.error-message {
  color: #ea3916;
}

.footer-container {
  width: 100%;
}

@media (min-width: 768px) {
  .input-container label {
    flex-basis: 1rem;
  }

  .title-section h1 {
    top: 0rem;
  }

  .login-translation {
    right: 10rem;
    bottom: 0rem;
  }

  .form-section {
    width: 80%;
  }
}

@media (min-width: 1280px) {
  .left-section h1 {
    font-size: 4.25rem;
  }

  .title-section {
    padding: 3rem 1rem 3rem 1rem;
  }

  .login-translation {
    right: 25rem;
  }

  .form-section {
    width: 40%;
  }
}

@media (min-width: 1920px) {
  .login-translation {
    right: 40rem;
  }

  .form-section {
    width: 30%;
  }
}
