.download-btn-top{
    margin-left: 0rem;
}

.download-btn-btm{
    margin-left: 0rem;
}

.subheader-content-treatments{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1140px;
    margin: 8rem auto 0 auto;
}

.subheader-content-treatments a{
    color: #FFF;
    margin-right: 1rem;
}
.subheader-content-treatments a:hover{
    text-decoration: none;
    color: #FFF;
}
.subheader-content-treatments>h3{
    font-size: var(--font-size-sm);
    color: #FFF;
}

@media (min-width: 667px) {
    .download-btn-top{
        float: right;
    }
    
    .download-btn-btm{
        float: right;
        margin-left:  15rem;
    }
}

@media (min-width: 760px) {
    .subheader-content-treatments{
        margin: 4rem auto 0 auto;
    }

    .download-btn-btm{
        float: right;
        margin-left:  21rem;
    }
}

@media (min-width: 1024px) {
    .download-btn-btm{
        float: right;
        margin-left:  38rem;
    }
}

@media (min-width: 1280px) {
    .download-btn-btm{
        float: right;
        margin-left:  42rem;
    }
}