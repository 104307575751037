.contact-form {
    max-width: 400px;
    margin: 0 auto;
}

.contact-input-container {
    margin-bottom: 20px;
    flex-direction: column;
}

.contact-label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 10px;
}

textarea {
    resize: vertical;
    min-height: 100px;
}

.next-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}

.next-btn:hover,
.next-btn:focus {
    background-color: #0056b3;
}