.next-button-container {
    color: #FFF;
    text-align: center;
    margin: 9rem 0 0;
    padding: 2rem 1.5rem;
    background-color: var(--primary-colour);
}
.center{
    display: grid;
    position: relative;
    text-align: center;
    bottom: 50px;
    margin-bottom: 1rem;
    padding: 0rem 1rem;
}