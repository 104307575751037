.body-container {
  max-width: 1140px;
  margin: 0rem auto;
  margin-top: 190px !important;
}
.six-steps-heading {
  position: relative;
  text-align: center;
  font-size: 2rem;
}

@media (max-width: 870px) {
  .body-container {
    margin-top: 290px !important;
  }
}

/* .six-steps-heading::after {
  content: "• • • • • •"; Creates six dots
  color: orangered; Red-orange color can also be adjusted
  font-size: 3.5rem;
  position: absolute;
  bottom: -3.5rem; Adjusts distance from heading
  left: 50%;
  transform: translateX(-50%);
  letter-spacing: 0.5rem; Space between dots
} */

.body-container-intro {
  max-width: 1240px;
  display: flex;
  height: 70vh;
}
.body-container-intro > a {
  align-self: center;
  height: min-content;
}
.body-container-intro > picture {
  display: flex;
  justify-content: right;
}
.body-container > h1:first-of-type {
  text-align: center;
}

.home-margin {
  margin-top: 20px !important;
}

.orangenext-btn-container-intro {
  color: var(--secondary-colour) !important;
  text-align: center;
  margin: 12rem 0rem 0rem 0rem;
  padding: 1rem 1.5rem;
}

.orangenext-btn-content-intro {
  max-width: 1100px;
  margin: 0 auto;
  color: var(--primary-colour) !important;
}

@media (min-width: 760px) {
  .orangenext-btn-container {
    text-align: right;
    margin-top: -9rem;
  }

  .orangenext-btn-container-intro {
    margin: 10rem 0rem 0rem 0rem;
  }
}

@media (max-width: 760px) {
  .body-container-intro {
    flex-direction: column;
  }
  .six-steps-heading {
    display: none;
  }
  .body-container-intro {
    height: 100%;
  }
}
