.side-nav-container-user {
  height: 100%;
  width: 100%;
  background-color: rgb(245, 245, 245);
}

.side-header-user {
  width: 100%;
  background-color: var(--secondary-colour);
  color: white;
  padding: 1rem 1.5rem 0;
}
.side-header-user h3 {
  margin-left: 2rem;
  font-size: 2rem;
  color: white;
}

.fixed-constent-user {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1140px;
  margin: 0 auto;
}

fixed-content-user .list-container-user {
  background-color: var(--secondary-colour);
  padding-bottom: 1.5rem !important;
  height: 100%;
}

.list-container-user li {
  display: inline-block !important;
}

.list-user {
  display: inline-block !important;
  color: white;
  height: 100%;
  padding-left: 0 !important;
}

li > a {
  cursor: pointer;
  color: white;
  /* color: #444; */
}

.list-user-item {
  margin-top: 2.5rem;
}
.list-user-item > a {
  position: relative;
  color: #fff !important;
  font-weight: 700 !important;
  margin-left: 2rem;
  right: 1rem;
  padding: 0.7rem 1rem !important;
  background-color: var(--secondary-colour) !important;
  border: white !important;
  border-style: solid !important;
  border-radius: 1rem !important;
  cursor: pointer;
  transition: all 0.3s ease-out;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.225);
}

.list-user-item > a:hover {
  text-decoration: none;
  background-color: rgba(250, 63, 8, 0.9) !important;
  color: var(--primary-colour) !important;
  border: var(--primary-colour) !important;
  border-style: solid !important;
}
.list-user-item > a.active {
  color: white !important;
  border: white !important;
  background-color: var(--primary-colour) !important;
  border-style: solid !important;
}
